import { ReactComponent as EyeClosedSvg } from 'assets/icons/eye/eyeClosed.svg';
import { ReactComponent as EyeOpenSvg } from 'assets/icons/eye/eyeOpen.svg';
import { FunctionComponent, useState } from 'react';
import { IInputProps, Input } from '../basic/input';

const PasswordInput: FunctionComponent<IInputProps> = (props: IInputProps) => {
    const [isEyeOpen, setEyeState] = useState(false);
    const toogleState = () => {
        setEyeState(!isEyeOpen);
    };
    return (
        <Input {...props} type={isEyeOpen ? 'text' : 'password'} icon={isEyeOpen ? <EyeOpenSvg onClick={toogleState}/> : <EyeClosedSvg onClick={toogleState} />}/>
    );
};
export { PasswordInput };
