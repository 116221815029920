import translations from 'i18n/translations';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources: translations,
    fallbackLng: 'es',
    react: {
        bindI18n: 'languageChanged',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    }
});

export default i18n;