import cellphonesLogin from 'assets/login/cellphonesLogin.png';
import logo from 'assets/logo.svg';
import Button from 'components/atoms/button';
import { Input, PasswordInput } from 'components/atoms/input';
import Link from 'components/atoms/link/link';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { login } from 'redux/modules/auth/actions';
import { RootReducer } from 'redux/reducers';
import ROUTES from 'routes';
import styles from './styles.scss';

export interface UserParams {
  email: string;
  password: string;
}
const Login: FunctionComponent = () => {
  /* Props */
  const { localStorage } = window;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const methods = useForm();
  const dispatch = useDispatch();
  const auth = useSelector(({ auth: { token } }: RootReducer) => token);
  const errorResponse = useSelector(
    ({ auth: { error } }: RootReducer) => error
  );
  const [hasError, setHasError] = useState(errorResponse !== null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  /* Effects */

  useEffect(() => {
    if (auth) {
      const location = localStorage.getItem('redirect') || ROUTES.ERROR._404;
      localStorage.removeItem('redirect');
      return navigate(location);
    }
  }, [auth]);

  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        setHasError(false);
      }, 2000);
    }
  }, [hasError]);

  /* Handlers */
  const doLogin = () => {
    dispatch(login({ email, password }));
  };
  const enableButton = () => {
    setButtonDisabled(!(email.length > 0 && password.length > 0));
  };
  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value.trim());
    enableButton();
  };
  const handlePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value.trim());
    enableButton();
  };
  const goToSignUp = () => {
    navigate(ROUTES.SIGNUP);
  };

  /* Render */

  return (
    <div className={styles.loginContainer}>
      <img className={styles.colkieLogo} src={logo} alt="colkie-logo" />
      <div className={styles.companionElement}>
        <h1 className={styles.title}>{t('login.images.title')}</h1>
        <h1 className={styles.title}>{t('login.images.titleExtra')}</h1>
        <p className={styles.subtitle}>{t('login.images.subtitle')}</p>
        <img src={cellphonesLogin} alt="cellphones with app" />
      </div>
      <FormProvider {...methods}>
        <div className={styles.login}>
          <h1 className={styles.title}>{t('login.form.title')}</h1>
          {hasError && <p className={styles.error}>{errorResponse?.message}</p>}
          <Input
            placeholder={t('login.form.inputs.user')}
            onChange={handleEmail}
            hasError={hasError}
            type="text"
          />
          <PasswordInput
            placeholder={t('login.form.inputs.password')}
            onChange={handlePassword}
            hasError={hasError}
          />
          <Button
            disabled={buttonDisabled}
            label={t('login.form.buttons.signIn')}
            onClick={doLogin}
          />
          <Link
            label={t('login.form.links.noAccount')}
            onClick={goToSignUp}
            underline
          />
        </div>
      </FormProvider>
    </div>
  );
};

export { Login };
