const config = {
    EMAIL: {
        REGEX: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/,
    },
    PASSWORD: {
        REGEX: /^(?=.*?[A-Z])(?!.*[\s])(?=.*?[a-z])(?=.*?[0-9])(?=.*?["·#$%&/()=¿?¡!*_\-:.;,{}[\]+<>`^|@]).{8,}$/,
    },
    LINKS: {
        APP_STORE: 'https://apps.apple.com/es/app/colkie/id1572065993?l=en',
        PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.colkie.app'
    }
};
type Alpha2toIETF = {
    [key: string]: string
}
export const language: Alpha2toIETF = {
    'AD': 'ca-AD',
    'AE': 'ar-AE',
    'AF': 'ps-AF',
    'AG': 'en-AG',
    'AI': 'en-AI',
    'AL': 'sq-AL',
    'AM': 'hy-AM',
    'AO': 'pt-AO',
    'AQ': 'en-AQ',
    'AR': 'es-AR',
    'AS': 'en-AS',
    'AT': 'de-AT',
    'AU': 'en-AU',
    'AW': 'nl-AW',
    'AX': 'sv-AX',
    'AZ': 'az-AZ',
    'BA': 'bs-BA',
    'BB': 'en-BB',
    'BD': 'bn-BD',
    'BE': 'nl-BE',
    'BF': 'fr-BF',
    'BG': 'bg-BG',
    'BH': 'ar-BH',
    'BI': 'fr-BI',
    'BJ': 'fr-BJ',
    'BL': 'fr-BL',
    'BM': 'en-BM',
    'BN': 'ms-BN',
    'BO': 'es-BO',
    'BQ': 'nl-BQ',
    'BR': 'pt-BR',
    'BS': 'en-BS',
    'BT': 'dz-BT',
    'BV': 'no-BV',
    'BW': 'en-BW',
    'BY': 'be-BY',
    'BZ': 'en-BZ',
    'CA': 'en-CA',
    'CC': 'en-CC',
    'CD': 'fr-CD',
    'CF': 'fr-CF',
    'CG': 'fr-CG',
    'CH': 'de-CH',
    'CI': 'fr-CI',
    'CK': 'en-CK',
    'CL': 'es-CL',
    'CM': 'fr-CM',
    'CN': 'zh-CN',
    'CO': 'es-CO',
    'CR': 'es-CR',
    'CU': 'es-CU',
    'CV': 'pt-CV',
    'CW': 'nl-CW',
    'CX': 'en-CX',
    'CY': 'el-CY',
    'CZ': 'cs-CZ',
    'DE': 'de-DE',
    'DJ': 'fr-DJ',
    'DK': 'da-DK',
    'DM': 'en-DM',
    'DO': 'es-DO',
    'DZ': 'ar-DZ',
    'EC': 'es-EC',
    'EE': 'et-EE',
    'EG': 'ar-EG',
    'EH': 'ar-EH',
    'ER': 'ti-ER',
    'ES': 'es-ES',
    'ET': 'en-ET',
    'FI': 'fi-FI',
    'FJ': 'en-FJ',
    'FK': 'en-FK',
    'FM': 'en-FM',
    'FO': 'fo-FO',
    'FR': 'fr-FR',
    'GA': 'fr-GA',
    'GB': 'en-GB',
    'GD': 'en-GD',
    'GE': 'ka-GE',
    'GF': 'fr-GF',
    'GG': 'en-GG',
    'GH': 'en-GH',
    'GI': 'en-GI',
    'GL': 'kl-GL',
    'GM': 'en-GM',
    'GN': 'fr-GN',
    'GP': 'fr-GP',
    'GQ': 'es-GQ',
    'GR': 'el-GR',
    'GS': 'en-GS',
    'GT': 'es-GT',
    'GU': 'en-GU',
    'GW': 'pt-GW',
    'GY': 'en-GY',
    'HK': 'zh-HK',
    'HM': 'en-HM',
    'HN': 'es-HN',
    'HR': 'hr-HR',
    'HT': 'ht-HT',
    'HU': 'hu-HU',
    'ID': 'id-ID',
    'IE': 'en-IE',
    'IL': 'he-IL',
    'IM': 'en-IM',
    'IN': 'en-IN',
    'IO': 'en-IO',
    'IQ': 'ar-IQ',
    'IR': 'fa-IR',
    'IS': 'is-IS',
    'IT': 'it-IT',
    'JE': 'en-JE',
    'JM': 'en-JM',
    'JO': 'ar-JO',
    'JP': 'ja-JP',
    'KE': 'sw-KE',
    'KG': 'ky-KG',
    'KH': 'km-KH',
    'KI': 'en-KI',
    'KM': 'ar-KM',
    'KN': 'en-KN',
    'KP': 'ko-KP',
    'KR': 'ko-KR',
    'KW': 'ar-KW',
    'KY': 'en-KY',
    'KZ': 'kk-KZ',
    'LA': 'lo-LA',
    'LB': 'ar-LB',
    'LC': 'en-LC',
    'LI': 'de-LI',
    'LK': 'si-LK',
    'LR': 'en-LR',
    'LS': 'en-LS',
    'LT': 'lt-LT',
    'LU': 'lb-LU',
    'LV': 'lv-LV',
    'LY': 'ar-LY',
    'MA': 'ar-MA',
    'MC': 'fr-MC',
    'MD': 'ro-MD',
    'ME': 'sr-ME',
    'MF': 'fr-MF',
    'MG': 'fr-MG',
    'MH': 'en-MH',
    'MK': 'mk-MK',
    'ML': 'fr-ML',
    'MM': 'my-MM',
    'MN': 'mn-MN',
    'MO': 'zh-MO',
    'MP': 'en-MP',
    'MQ': 'fr-MQ',
    'MR': 'ar-MR',
    'MS': 'en-MS',
    'MT': 'mt-MT',
    'MU': 'mfe-MU',
    'MV': 'dv-MV',
    'MW': 'en-MW',
    'MX': 'es-MX',
    'MY': 'ms-MY',
    'MZ': 'pt-MZ',
    'NA': 'en-NA',
    'NC': 'fr-NC',
    'NE': 'fr-NE',
    'NF': 'en-NF',
    'NG': 'en-NG',
    'NI': 'es-NI',
    'NL': 'nl-NL',
    'NO': 'nb-NO',
    'NP': 'ne-NP',
    'NR': 'en-NR',
    'NU': 'en-NU',
    'NZ': 'en-NZ',
    'OM': 'ar-OM',
    'PA': 'es-PA',
    'PE': 'es-PE',
    'PF': 'fr-PF',
    'PG': 'en-PG',
    'PH': 'en-PH',
    'PK': 'ur-PK',
    'PL': 'pl-PL',
    'PM': 'fr-PM',
    'PN': 'en-PN',
    'PR': 'es-PR',
    'PS': 'ar-PS',
    'PT': 'pt-PT',
    'PW': 'en-PW',
    'PY': 'es-PY',
    'QA': 'ar-QA',
    'RE': 'fr-RE',
    'RO': 'ro-RO',
    'RS': 'sr-RS',
    'RU': 'ru-RU',
    'RW': 'fr-RW',
    'SA': 'ar-SA',
    'SB': 'en-SB',
    'SC': 'fr-SC',
    'SD': 'ar-SD',
    'SE': 'sv-SE',
    'SG': 'zh-SG',
    'SH': 'en-SH',
    'SI': 'sl-SI',
    'SJ': 'nb-SJ',
    'SK': 'sk-SK',
    'SL': 'en-SL',
    'SM': 'it-SM',
    'SN': 'fr-SN',
    'SO': 'so-SO',
    'SR': 'nl-SR',
    'SS': 'en-SS',
    'ST': 'pt-ST',
    'SV': 'es-SV',
    'SX': 'nl-SX',
    'SY': 'ar-SY',
    'SZ': 'en-SZ',
    'TC': 'en-TC',
    'TD': 'fr-TD',
    'TF': 'fr-TF',
    'TG': 'fr-TG',
    'TH': 'th-TH',
    'TJ': 'tg-TJ',
    'TK': 'tk-TK',
    'TL': 'pt-TL',
    'TM': 'tk-TM',
    'TN': 'ar-TN',
    'TO': 'to-TO',
    'TR': 'tr-TR',
    'TT': 'en-TT',
    'TV': 'en-TV',
    'TW': 'zh-TW',
    'TZ': 'sw-TZ',
    'UA': 'uk-UA',
    'UG': 'sw-UG',
    'UM': 'en-UM',
    'US': 'en-US',
    'UY': 'es-UY',
    'UZ': 'uz-UZ',
    'VA': 'it-VA',
    'VC': 'en-VC',
    'VE': 'es-VE',
    'VG': 'en-VG',
    'VI': 'en-VI',
    'VN': 'vi-VN',
    'VU': 'en-VU',
    'WF': 'fr-WF',
    'WS': 'en-WS',
    'YE': 'ar-YE',
    'YT': 'fr-YT',
    'ZA': 'af-ZA',
    'ZM': 'en-ZM',
    'ZW': 'en-ZW',
};
export default config;