const ROUTES = {
    HOME: '/',
    AUTH:'/auth',
    LOGIN: '/auth/signin',
    SIGNUP: '/auth/signup',
    SUBSCRIPTION:'/subscribe/:channelId',
    SUBSCRIPTION_SUCCESS:  '/subscription/success',
    SUBSCRIPTION_CANCEL:'/subscription/cancel',
    PROFILE:'/profile/:artistname',
    ERROR:{
        _404:'/error/404'
    },
    API: {
        SIGNIN: '/api/auth/signin',
        USER: {
            PROFILE: 'api/v1/user/:username/profile',
            ME: 'api/v1/user/me',
            PRICE: 'api/v1/user/store/products'
        },
    },
    CHAT_SERVER: {
        CHANNEL: 'chat-server/v3/channels',
        CHANNEL_SUBSCRIPTION: 'chat-server/v2/channels/:channelId/subscription'
    },
    PRODUCT_GATEWAY: {
        PAY_IN: 'product-gateway/v1/payIn',
    }
};

export default ROUTES;