export default {
  translation: {
    languageId: 'en',
    login: {
      images: {
        title: 'Do you want to be part of',
        titleExtra: 'a new musical revolution?',
        subtitle:
          'Support your favorite artists and start interacting in exclusive and unique ways with them.',
      },
      form: {
        title: 'Hello again!',
        inputs: {
          user: 'Email',
          password: 'Password',
        },
        links: {
          forgot: '¿Have you forgotten your password?',
          noAccount: 'I don’t have an account yet',
        },
        buttons: {
          signIn: 'Sign in',
        },
      },
    },
    signup: {
      form: {
        title: 'Download Colkie',
        subtitle: 'And connect with your favorite artists like never before.',
        buttons: {
          android: 'Google Play',
          apple: 'App Store',
        },
      },
    },
    subscription: {
      title: 'Membership',
      header: {
        title: 'Community of',
        subtitle:
          'Become a member and discover a new way to get closer to the artist you love:',
      },
      titleFeatures: 'Membership benefits',
      features: {
        memberPass: {
          title: 'Gold Pass',
          subtitle: 'Access exclusive benefits and real life experiences',
        },
        channel: {
          title: 'Customized Badge',
          subtitle: 'Stand out of with your Supporter badge',
        },
        exclusiveContent: {
          title: 'Experience',
          subtitle:
            'Enjoy premium content, unlimited messages and private events',
        },
      },
      pricing: {
        title: 'Monthly membership',
        subtitle: 'Support your artist and enjoy exclusive benefits.',
        price: '{{value, currency}}',
      },
      button: {
        title: 'Become premium',
      },
    },
    membercard: {
      officialMember: 'Official member card',
      memberSince: 'Member since',
      memberFor: 'Member for',
    },
    subscriptionSuccess: {
      title: 'Congrats!',
      subtitle: "You've subscribed to ",
      text: 'Your subscription will renew automatically every month',
      buttons: {
        openApp: 'Open app',
      },
    },
    subscriptionCancel: {
      title: 'You left the payment process',
      subtitle: 'You can start it again at any time',
      buttons: {
        tryAgain: 'Try again',
      },
    },
    errors: {
      notFound404: {
        error: '404',
        title: 'Oops! Page not found!',
        subtitle: 'The page you are looking for does not exist.',
        buttons: {
          back: 'Back',
        },
      },
    },
    profile: {
      juanes: {
        title: "¡Join Juanes' official fan community!",
        benefits: {
          title: 'Be part of his community of <strong>real fans</strong> and:',
          first: 'interact directly with him',
          second: 'get the latest news before anyone else',
          third:
            'discover a more personal side of him through his exclusive content',
          fourth: 'meet and connect with other fans like you',
          fifth:
            'share your content and experience with the rest of the fandom',
        },
        downloadText:
          "Download the Colkie app to access Juanes' community and <br/> <strong>feel closer to him than ever</strong>!",
        mainImage:
          'https://resources.colkie.com/landing-pages/juanes_profile.png',
      },
      aitana: {
        title: "¡Join Aitana's official fan community!",
        benefits: {
          title: 'Be part of her community of <strong>real fans</strong> and:',
          first: 'interact directly with her',
          second: 'get the latest news before anyone else',
          third:
            'discover a more personal side of her through her exclusive content',
          fourth: 'meet and connect with other fans like you',
          fifth:
            'share your content and experience with the rest of the fandom',
        },
        downloadText:
          "Download the Colkie app to access Aitana's community and <br/> <strong>feel closer to her than ever</strong>!",
        mainImage:
          'https://resources.colkie.com/landing-pages/aitana_profile.png',
      },
      davidbisbal: {
        title: "¡Join David Bisbal's official fan community!",
        benefits: {
          title: 'Be part of his community of <strong>real fans</strong> and:',
          first: 'interact directly with him',
          second: 'get the latest news before anyone else',
          third:
            'discover a more personal side of him through his exclusive content',
          fourth: 'meet and connect with other fans like you',
          fifth:
            'share your content and experience with the rest of the fandom',
        },
        downloadText:
          "Download the Colkie app to access David Bisbal's community and <br/> <strong>feel closer to him than ever</strong>!",
        mainImage:
          'https://resources.colkie.com/landing-pages/bisbal_profile.png',
      },
      morat: {
        title: "¡Join Morat's official fan community!",
        benefits: {
          title:
            'Be part of their community of <strong>real fans</strong> and:',
          first: 'interact directly with them',
          second: 'get the latest news before anyone else',
          third:
            'discover a more personal side of them through their exclusive content',
          fourth: 'meet and connect with other fans like you',
          fifth:
            'share your content and experience with the rest of the fandom',
        },
        downloadText:
          "Download the Colkie app to access Morat's community and <br/> <strong>feel closer to them than ever</strong>!",
        mainImage:
          'https://resources.colkie.com/landing-pages/morat_profile.png',
      },
      buttons: {
        androidLink:
          'https://play.google.com/store/apps/details?id=com.colkie.app&hl=es&gl=US',
        androidText: 'Download on Android',
        iosLink:
          'https://apps.apple.com/es/app/colkie-mucho-m%C3%A1s-que-m%C3%BAsica/id1572065993',
        iosText: 'Download on iOS',
      },
    },
  },
};
