import Button from 'components/atoms/button';
import MainHeader from 'components/templates/layout/header/Header';
import { ComponentProps, FunctionComponent } from 'react';
import styles from './styles.scss';

export interface ErrorProps extends ComponentProps<'div'> {
  image?: string;
  error?: string;
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  handler?: () => void;
}
const ErrorLayout: FunctionComponent<ErrorProps> = (props: ErrorProps) => (
  <div className={styles.fullScreen_template}>
    <MainHeader />
    <div className={styles.error404}>
      <div className={styles.container}>
        <div className={styles.message}>
          {props.error && <h1 className={styles.error}>{props.error}</h1>}
          {props.title && <h3 className={styles.title}>{props.title}</h3>}
          {props.subtitle && (
            <p className={styles.subtitle}>{props.subtitle}</p>
          )}
          {props.buttonLabel && (
            <Button
              className={styles.backButton}
              label={props.buttonLabel}
              onClick={props.handler}
            />
          )}
        </div>
        <div className={styles.illustration}>
          {props.image && (
            <img
              className={styles.imgCloud}
              src={props.image}
              alt={props.error}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ErrorLayout;
