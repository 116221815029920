import ROUTES from 'routes';
import Axios from 'services/client';
import { Channel, Price } from '../models/channel.model';
import { ActiveSubscription, ActiveSubscriptionsResponse } from '../models/subscription.model';

const headerPlatform = {headers : {'x-platform': 'GOOGLE'}};

export const fetchChannelData = (channelId: string) => Axios.get<Channel>(`/${ROUTES.CHAT_SERVER.CHANNEL}/${channelId}`, headerPlatform);

export const fetchSubscriptionData = (channelId: string) => Axios.get<ActiveSubscription>(`/${ROUTES.CHAT_SERVER.CHANNEL_SUBSCRIPTION.replace(':channelId', channelId)}`, headerPlatform);

export const fetchSubscriptions = () => Axios.get<ActiveSubscriptionsResponse>(`/${ROUTES.CHAT_SERVER.CHANNEL}/subscriptions`, headerPlatform);

export const fetchLevelPrice = (externalProductId: string) => Axios.get<Price>(`/${ROUTES.API.USER.PRICE}/${externalProductId}`);