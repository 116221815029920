import Axios, { AxiosRequestHeaders } from 'axios';
import store from 'redux/store';

export const HEADERS: AxiosRequestHeaders = {
  Accept: 'application/json',
  'Content-type': 'application/json',
  'Cache-control': 'no-cache',
  'x-platform': 'WEB',
};

Axios.interceptors.request.use(async (config) => {
  const tokenValue = store.store.getState().auth.token;
  const headers = {
    ...HEADERS,
    'x-access-token': tokenValue || '',
    Authorization: `Bearer ${tokenValue}`,
  };
  const platform =
    config?.headers && config.headers['x-platform']
      ? config.headers['x-platform']
      : 'WEB';
  config.headers = { ...headers, 'x-platform': platform };
  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error.message);
  }
);

export default Axios;
