import { ComponentProps, FunctionComponent } from 'react';

import styles from './styles.scss';

const Container: FunctionComponent<ComponentProps<'div'>> = (props: ComponentProps<'div'>) => (
        <div className={styles.container}>
            {props.children}
        </div>
    );

export { Container };
