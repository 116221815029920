import { ReactComponent as AndroidSVG } from 'assets/icons/android.svg';
import { ReactComponent as AppleSVG } from 'assets/icons/apple.svg';
import downloadApp from 'assets/signup/download_app.png';
import Button from 'components/atoms/button';
import config from 'config/config';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.scss';

export interface UserParams {
  email: string;
  password: string;
}
const SignUpPage: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.companionElement}>
        <img src={downloadApp} alt="download the app" />
      </div>
      <div className={styles.signup}>
        <h1 className={styles.title}>{t('signup.form.title')}</h1>
        <p className={styles.subtitle}>{t('signup.form.subtitle')}</p>
        <div className={styles.buttonsContainer}>
          <Button
            label={t('signup.form.buttons.apple')}
            className={styles.blackButton}
            icon={<AppleSVG />}
            link={config.LINKS.APP_STORE}
          />
          <Button
            label={t('signup.form.buttons.android')}
            className={styles.blackButton}
            icon={<AndroidSVG />}
            link={config.LINKS.PLAY_STORE}
          />
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
