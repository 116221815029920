import DefaultProfileImage from 'assets/subscription/default-profile-image.svg';
import ExclusiveContentSvg from 'assets/subscription/exclusive_content.svg';
import MemberCardSvg from 'assets/subscription/membership_icon_card.svg';
import ChannelSvg from 'assets/subscription/membership_icon_chat_room.svg';
import Button from 'components/atoms/button';
import { MemberCard, Tier } from 'components/atoms/memberCard/memberCard';
import Container from 'components/molecules/container';
import { ComponentProps, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  getChannelToSubscribe,
  getLevelPrice,
  getSubscriptionInfo,
  subscribe,
} from 'redux/modules/subscription/actions';
import { getUserMe } from 'redux/modules/user/actions';
import { RootReducer } from 'redux/reducers';
import ROUTES from 'routes';
import { AccessLevel, Channel } from 'services/domain/models/channel.model';
import { ActiveSubscription } from 'services/domain/models/subscription.model';
import { TransactionType } from 'services/domain/models/transaction.model';
import { SubscriptionRequest } from 'services/domain/subscription/models/subscription.model';
import { getLanguageCode, localizePrice } from 'utils/formating';
import styles from './styles.scss';

interface Feature {
  icon: string;
  title: string;
  subtitle: string;
}

const SubscriptionPage: FunctionComponent<ComponentProps<'div'>> = (
  props: ComponentProps<'div'>
) => {
  /** Props */
  const channelInfo: Channel | null = useSelector(
    ({ subscriptions: { channel } }: RootReducer) => channel
  );
  const highestAccessLevel: AccessLevel | null = useSelector(
    ({ subscriptions: { highestLevel } }: RootReducer) => highestLevel
  );
  const activeSubscription: ActiveSubscription | null = useSelector(
    ({ subscriptions: { subscriptionInfo } }: RootReducer) => subscriptionInfo
  );
  const notFound404: Boolean = useSelector(
    ({ subscriptions: { notFound } }: RootReducer) => notFound
  );
  const levelPrice = useSelector(
    ({ subscriptions: { price } }: RootReducer) => price
  );
  const stripe = useSelector(
    ({ subscriptions: { sessionUrl } }: RootReducer) => sessionUrl
  );
  const user = useSelector(({ user: { me } }: RootReducer) => me);
  const artist = channelInfo?.owner?.username;
  const imageCover = channelInfo?.owner?.imgProfile || DefaultProfileImage;
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  /** Features */
  const features: Feature[] = [
    {
      icon: MemberCardSvg,
      title: t('subscription.features.memberPass.title'),
      subtitle: t('subscription.features.memberPass.subtitle'),
    },
    {
      icon: ChannelSvg,
      title: t('subscription.features.channel.title'),
      subtitle: t('subscription.features.channel.subtitle'),
    },
    {
      icon: ExclusiveContentSvg,
      title: t('subscription.features.exclusiveContent.title'),
      subtitle: t('subscription.features.exclusiveContent.subtitle'),
    },
  ];

  /** Effects */

  useEffect(() => {
    if (channelId) {
      dispatch(getChannelToSubscribe(channelId));
      dispatch(getSubscriptionInfo(channelId));
      dispatch(getUserMe());
      localStorage.setItem('productId', channelId);
    }
  }, []);
  useEffect(() => {
    if (stripe) {
      window.location.href = stripe.sessionUrl;
    }
  }, [stripe]);

  useEffect(() => {
    if (notFound404) {
      navigate(ROUTES.ERROR._404);
    }
  }, [notFound404]);

  useEffect(() => {
    if (highestAccessLevel?.externalProductId) {
      dispatch(getLevelPrice(highestAccessLevel.externalProductId));
    }
  }, [highestAccessLevel]);

  useEffect(() => {
    if (
      activeSubscription?.level === highestAccessLevel?.level &&
      activeSubscription?.autoRenewal === true
    ) {
      navigate(ROUTES.SUBSCRIPTION_SUCCESS);
    }
  }, [activeSubscription]);
  /** Handlers */
  const featuresComponent = (feature: any, index: number) => (
    <div key={index} className={styles.subsFeatures}>
      <figure className={styles.featureImage}>
        <img src={feature.icon} alt={t(feature.title)} />
      </figure>
      <div>
        <p className={styles.featureTitle}>{t(feature.title)}</p>
        <p className={styles.featureSubtitle}>{t(feature.subtitle)}</p>
      </div>
    </div>
  );
  const trySubscribe = () => {
    if (!channelInfo?._id) {
      return;
    }
    const { _id: productId } = channelInfo;
    const { localStorage } = window;
    localStorage.setItem('productId', productId);
    const checkoutConfig: SubscriptionRequest = {
      productId,
      type: TransactionType.CHANNEL_SUBSCRIPTION,
      successUrl: window.location.origin + ROUTES.SUBSCRIPTION_SUCCESS,
      cancelUrl: window.location.origin + ROUTES.SUBSCRIPTION_CANCEL,
    };
    dispatch(subscribe(checkoutConfig));
  };
  return (
    <div {...props}>
      <Container>
        <section className={styles.subscriptionContainer}>
          {/* <MainHeader /> */}
          <header className={styles.subsHeader}>
            <p className={styles.title}>{t('subscription.title')}</p>
            <MemberCard
              tier={Tier.GOLD}
              artistImage={imageCover || ''}
              artistUsername={artist || ''}
              fanUsername={user?.username || ''}
              firstSubscriptionDate={activeSubscription?.firstSubscriptionDate}
              memberId={activeSubscription?.memberId}
              membershipCount={activeSubscription?.membershipCount}
            />
          </header>
          <article className={styles.subsFeaturesContainer}>
            <h3 className={styles.subtitleFeatures}>
              {t('subscription.titleFeatures')}
            </h3>
            <hr className={styles.separator} />
            {features.map(featuresComponent)}
          </article>
          <footer className={styles.subsFooter}>
            <div className={styles.priceContainer}>
              <div>
                <h3>{t('subscription.pricing.title')}</h3>
                <p className={styles.greyText}>
                  {t('subscription.pricing.subtitle')}
                </p>
              </div>
              {levelPrice && (
                <p className={styles.price}>
                  {t('subscription.pricing.price', {
                    value: localizePrice(levelPrice),
                    currency: levelPrice.currency?.toUpperCase(),
                    locale: getLanguageCode(levelPrice?.country),
                  })}
                </p>
              )}
            </div>
            <Button
              label={t('subscription.button.title')}
              onClick={trySubscribe}
            />
          </footer>
        </section>
      </Container>
    </div>
  );
};

export default SubscriptionPage;
