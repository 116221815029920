import spinner from 'assets/lotties/spinner.json';
import Overlay from 'components/molecules/overlay';
import Lottie from 'lottie-react';
import { ComponentProps, FunctionComponent } from 'react';
import styles from './styles.scss';

const Loader: FunctionComponent<ComponentProps<'div'>> = (props: ComponentProps<'div'>) => (
    <Overlay>
        <div {...props} className={styles.loaderContainer}>
            <Lottie animationData={spinner} />
        </div>
    </Overlay>
);

export { Loader };
