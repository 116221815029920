import logo from 'assets/logo.svg';
import { ComponentProps, FunctionComponent } from 'react';
import styles from './styles.scss';

const MainHeader: FunctionComponent<ComponentProps<'header'>> = (props: ComponentProps<'header'>) => (
        <header {...props} className={styles.header}>
            <img src={logo} alt="colkie-logo" />
        </header>
    );

export default MainHeader;