export default {
  translation: {
    languageId: 'es',
    login: {
      images: {
        title: '¿Quieres ser parte de una',
        titleExtra: 'nueva revolución musical?',
        subtitle:
          'Apoya a tus artistas favoritos y comienza a interactuar de forma exclusiva y única con ellos.',
      },
      form: {
        title: '¡Hola de nuevo!',
        inputs: {
          user: 'Email',
          password: 'Contraseña',
        },
        links: {
          forgot: '¿Has olvidado tu contraseña?',
          noAccount: 'No tengo cuenta aún',
        },
        buttons: {
          signIn: 'Iniciar sesión',
        },
      },
    },
    signup: {
      form: {
        title: 'Descarga Colkie',
        subtitle: 'Y conecta con tus artistas favoritos como nunca antes',
        buttons: {
          android: 'Google Play',
          apple: 'App Store',
        },
      },
    },
    subscription: {
      title: 'Suscripción',
      header: {
        title: 'Comunidad de',
        subtitle:
          'Hazte miembro y descubre una nueva manera de acercarte a tu artista favorito:',
      },
      titleFeatures: 'Beneficios de membresía',
      features: {
        memberPass: {
          title: 'Gold Pass',
          subtitle: 'Accede a beneficios exclusivos y experiencias únicas',
        },
        channel: {
          title: 'Distintivo Personalizado',
          subtitle: 'Destaca con tu insignia de Supporter',
        },
        exclusiveContent: {
          title: 'Experiencia mejorada',
          subtitle:
            'Disfruta de contenido premium, mensajes ilimitados y eventos privados',
        },
      },
      pricing: {
        title: 'Membresía mensual',
        subtitle: 'Apoya a tu artista y disfruta de beneficios exclusivos.',
        price: '{{value, currency}}',
      },
      button: {
        title: 'Hazte premium',
      },
    },
    membercard: {
      officialMember: 'Tarjeta de miembro oficial',
      memberSince: 'Miembro desde',
      memberFor: 'Miembro por',
    },
    subscriptionSuccess: {
      title: '¡Felicidades!',
      subtitle: 'Te has suscrito a ',
      text: 'Tu suscripción se renovará automáticamente todos los meses',
      buttons: {
        openApp: 'Ir a la comunidad',
      },
    },
    subscriptionCancel: {
      title: 'Abandonaste el proceso de pago',
      subtitle: 'Puedes volver a iniciarlo en cualquier momento',
      buttons: {
        tryAgain: 'Intentar de nuevo',
      },
    },
    errors: {
      notFound404: {
        error: '404',
        title: '¡Ups! Página no encontrada',
        subtitle: 'Parece que la página que buscas no existe.',
        buttons: {
          back: 'Volver',
        },
      },
    },
    profile: {
      juanes: {
        title: '¡Únete a la comunidad oficial de Juanes!',
        benefits: {
          title:
            'Forma parte de su comunidad de <strong>auténticos fans</strong> en la que podrás:',
          first: 'interactuar directamente con él',
          second: 'enterarte de sus últimas novedades antes que nadie',
          third:
            'descubrir su lado más real a través de su contenido exclusivo',
          fourth: 'conocer y conectar con otros fans como tú',
          fifth: 'compartir tu contenido y experiencia con el resto del fandom',
        },
        downloadText:
          'Descarga la app de Colkie para poder acceder a la comunidad oficial de Juanes y <br/>¡<strong>siéntete más cerca que nunca de él</strong>!',
        mainImage:
          'https://resources.colkie.com/landing-pages/juanes_profile.png',
      },
      aitana: {
        title: '¡Únete a la comunidad oficial de Aitana!',
        benefits: {
          title:
            'Forma parte de su comunidad de <strong>auténticos fans</strong> en la que podrás:',
          first: 'interactuar directamente con ella',
          second: 'enterarte de sus últimas novedades antes que nadie',
          third:
            'descubrir su lado más real a través de su contenido exclusivo',
          fourth: 'conocer y conectar con otros fans como tú',
          fifth: 'compartir tu contenido y experiencia con el resto del fandom',
        },
        downloadText:
          'Descarga la app de Colkie para poder acceder a la comunidad oficial de Aitana y <br/>¡<strong>siéntete más cerca que nunca de ella</strong>!',
        mainImage:
          'https://resources.colkie.com/landing-pages/aitana_profile.png',
      },
      davidbisbal: {
        title: '¡Únete a la comunidad oficial de David Bisbal!',
        benefits: {
          title:
            'Forma parte de su comunidad de <strong>auténticos fans</strong> en la que podrás:',
          first: 'interactuar directamente con él',
          second: 'enterarte de sus últimas novedades antes que nadie',
          third:
            'descubrir su lado más real a través de su contenido exclusivo',
          fourth: 'conocer y conectar con otros fans como tú',
          fifth: 'compartir tu contenido y experiencia con el resto del fandom',
        },
        downloadText:
          'Descarga la app de Colkie para poder acceder a la comunidad oficial de David Bisbal y <br/>¡<strong>siéntete más cerca que nunca de él</strong>!',
        mainImage:
          'https://resources.colkie.com/landing-pages/bisbal_profile.png',
      },
      morat: {
        title: '¡Únete a la comunidad oficial de Morat!',
        benefits: {
          title:
            'Forma parte de su comunidad de <strong>auténticos fans</strong> en la que podrás:',
          first: 'interactuar directamente con ellos',
          second: 'enterarte de sus últimas novedades antes que nadie',
          third:
            'descubrir su lado más real a través de su contenido exclusivo',
          fourth: 'conocer y conectar con otros fans como tú',
          fifth: 'compartir tu contenido y experiencia con el resto del fandom',
        },
        downloadText:
          'Descarga Colkie para poder acceder a la comunidad oficial de Morat y <br/>¡<strong>siéntete más cerca que nunca de ellos</strong>!',
        mainImage:
          'https://resources.colkie.com/landing-pages/morat_profile.png',
      },
      buttons: {
        androidLink:
          'https://play.google.com/store/apps/details?id=com.colkie.app&hl=es&gl=US',
        androidText: 'Descargar para Android',
        iosLink:
          'https://apps.apple.com/es/app/colkie-mucho-m%C3%A1s-que-m%C3%BAsica/id1572065993',
        iosText: 'Descargar para iOS',
      },
    },
  },
};
