import ConnectionLost from 'assets/errors/illustration/connectionLost.png';
import { useTranslation } from 'react-i18next';
import ErrorLayout, { ErrorProps } from '../error';

const Error404 = () => {
    const { t } = useTranslation();
    const error: ErrorProps = {
        image: ConnectionLost,
        error: t('errors.notFound404.error'),
        title: t('errors.notFound404.title'),
        subtitle: t('errors.notFound404.subtitle'),
        buttonLabel: t('errors.notFound404.buttons.back')
    };

    return (<ErrorLayout {...error}/>);
};

export default Error404;