import { ComponentProps, FunctionComponent, ReactElement } from 'react';
import styles from './styles.scss';

export interface IButtonProps extends ComponentProps<'button'> {
    label: string;
    icon?: ReactElement<any, any>;
    link?: string;
}

const Button: FunctionComponent<IButtonProps> = (props: IButtonProps) => {

    const handleClickLink = () => {
        window.open(props.link,'_blank');
    };
    return (
    <button {...props} className={`${styles.btn} ${props.className} ${props.disabled && styles.buttonDisabled}`} onClick={props.link ? handleClickLink : props.onClick}>
        {props.icon && <span className={styles.icon}>
                {props.icon}
            </span>}
        {props.label}
    </button>
    );
};

export { Button };
