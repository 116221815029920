import { combineReducers } from 'redux';

import { authReducer as auth } from './modules/auth/reducer';
import { globalReducer as global } from './modules/global/reducer';
import { subscriptionReducer as subscriptions } from './modules/subscription/reducer';
import { userReducer as user } from './modules/user/reducer';

const reducers = combineReducers({
    auth,
    global,
    subscriptions,
    user
});

export type RootReducer = ReturnType<typeof reducers>;
export default reducers;