import { ProfileResponse } from 'services/domain/user/models/profile.model';
import { Me } from 'services/domain/user/models/user.model';

const Actions = {
    PROFILE: 'PROFILE',
    PROFILE_PENDING: 'PROFILE_PENDING',
    PROFILE_FULFILLED: 'PROFILE_FULFILLED',
    PROFILE_REJECTED: 'PROFILE_REJECTED',

    GET_ME: 'ME',
    GET_ME_PENDING: 'ME_PENDING',
    GET_ME_FULFILLED: 'ME_FULFILLED',
    GET_ME_REJECTED: 'ME_REJECTED',
};

interface UserState {
    profile: ProfileResponse | null;
    me: Me | null;
}

const initialState: UserState = {
    profile: null,
    me: null
};

const userReducer = (state: UserState = initialState, action: any) => {
    switch(action.type) {
        case Actions.PROFILE_FULFILLED:
            return {...state, profile: action.payload.data};
        case Actions.GET_ME_FULFILLED:
            return {...state, me: action.payload.data};
        default: return state;
    }
};
export { Actions as userActions, userReducer };
