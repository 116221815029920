import {
  fetchChannelData,
  fetchLevelPrice,
  fetchSubscriptionData,
  fetchSubscriptions,
} from 'services/domain/channel/channel.service';
import { doSubscription } from 'services/domain/subscription';
import { SubscriptionRequest } from 'services/domain/subscription/models/subscription.model';
import { subscriptionActions } from './reducer';

const getChannelToSubscribe = (channelId: string) => ({
  type: subscriptionActions.CHANNEL_TO_SUBSCRIBE,
  payload: fetchChannelData(channelId)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response)),
});
const getSubscriptionInfo = (channelId: string) => ({
  type: subscriptionActions.SUBSCRIPTION,
  payload: fetchSubscriptionData(channelId)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response)),
});

const getSubscriptions = () => ({
  type: subscriptionActions.SUBSCRIPTION,
  payload: fetchSubscriptions()
    .then((data) => data)
    .catch((error) => Promise.reject(error.response)),
});

const getLevelPrice = (externalProductId: string) => ({
  type: subscriptionActions.GET_LEVEL_PRICE,
  payload: fetchLevelPrice(externalProductId)
    .then((data) => data)
    .catch((error) => Promise.reject(error.response)),
});

const subscribe = (params: SubscriptionRequest) => ({
  type: subscriptionActions.SUBSCRIBE,
  payload: doSubscription(params).then((data) => data),
});

export {
  subscribe,
  getChannelToSubscribe,
  getSubscriptionInfo,
  getSubscriptions,
  getLevelPrice,
};
