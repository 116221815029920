import logo from 'assets/logo.svg';
import Button from 'components/atoms/button';
import MainHeader from 'components/templates/layout/header/Header';
import Loader from 'components/templates/loader';
import { ComponentProps, FunctionComponent, useEffect } from 'react';
import { MobileOnlyView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getChannelToSubscribe } from 'redux/modules/subscription/actions';
import { getUserProfile } from 'redux/modules/user/actions';
import { RootReducer } from 'redux/reducers';
import ROUTES from 'routes';
import { Channel } from 'services/domain/models/channel.model';
import styles from './styles.scss';

const SubscriptionSuccessPage: FunctionComponent<ComponentProps<'section'>> = (
  props: ComponentProps<'section'>
) => {
  /** Props */
  const isLoading = useSelector(
    ({ global: { loading } }: RootReducer) => loading
  );
  const channelInfo: Channel | null = useSelector(
    ({ subscriptions: { channel } }: RootReducer) => channel
  );
  const profileData = useSelector(
    ({ user: { profile } }: RootReducer) => profile
  );
  const { localStorage } = window;
  const channelId = localStorage.getItem('productId');
  const imageCover = channelInfo?.owner?.imgProfile;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /** Effects */
  useEffect(() => {
    if (!channelId) {
      navigate(ROUTES.ERROR._404);
    }
    dispatch(getChannelToSubscribe(channelId!));
  }, []);
  useEffect(() => {
    if (channelInfo?.owner?.username) {
      dispatch(getUserProfile(channelInfo.owner.username));
    }
  }, [channelInfo]);

  const openApp = () => {
    if (!profileData?.user?.shareLink) {
      return;
    }
    window.location.assign(profileData.user.shareLink);
  };
  /** Render */
  return (
    <section {...props} className={styles.subscription_page}>
      <MainHeader />
      <article className={styles.subscriptionContainer}>
        <img className={styles.colkieLogo} src={logo} alt="colkie-logo" />
        {imageCover && (
          <header className={styles.headerStars}>
            <div className={styles.imageCoverContainer}>
              <img
                src={imageCover}
                alt={channelInfo?.owner?.name || 'Artist image'}
              />
            </div>
          </header>
        )}
        <h1 className={styles.title}>{t('subscriptionSuccess.title')}</h1>
        <h2 className={styles.subtitle}>
          {t('subscriptionSuccess.subtitle')}
          <span className={styles.capitalize}>
            {channelInfo?.owner?.username}
          </span>
        </h2>
        <p className={styles.text}>{t('subscriptionSuccess.text')}</p>
        {profileData?.user?.shareLink && (
          <MobileOnlyView>
            <Button
              className={styles.btnOpenApp}
              label={t('subscriptionSuccess.buttons.openApp')}
              onClick={openApp}
            />
          </MobileOnlyView>
        )}
      </article>
      {isLoading && <Loader />}
    </section>
  );
};

export default SubscriptionSuccessPage;
