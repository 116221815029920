const Actions = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
};

const startLoading = () => ({
    type: Actions.START_LOADING
});

const stopLoading = () => ({
    type: Actions.STOP_LOADING
});
export { Actions as globalActions, startLoading, stopLoading };
