import Container from 'components/molecules/container';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootReducer } from 'redux/reducers';
import Loader from '../../loader';
import Footer from '../footer/Footer';
import MainHeader from '../header/Header';
import styles from './styles.scss';

const MainLayout = () => {
    const isLoading = useSelector(({global: { loading }}:RootReducer) => loading);

return (

    <section className={styles.mainContainer}>
        <MainHeader />
        <article className={styles.mainContent}>
            <Container>
                <Outlet />
            </Container>
        </article>
        {isLoading && <Loader />}
        {/* <FooterCookies /> */}
        <Footer />
    </section>
  );
};

export default MainLayout;
