// import parser from 'html-react-parser';
import { FunctionComponent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ROUTES from 'routes';
// import './styles.scss'; //FIXME: styles.css is not been imported, temporary solution with style tag

const ProfilePage: FunctionComponent = () => {
  const params = useParams();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const artistsRegistred = ['morat', 'davidbisbal', 'aitana', 'juanes'];

  useEffect(() => {
    if (!artistsRegistred.includes(params.artistname ?? '')) {
      navigation(ROUTES.ERROR._404);
    }
  }, [params]);

  const { artistname } = params;
  const benefitsBuilder = () => {
    const benefits = [
      t(`profile.${artistname}.benefits.first`),
      t(`profile.${artistname}.benefits.second`),
      t(`profile.${artistname}.benefits.third`),
      t(`profile.${artistname}.benefits.fourth`),
      t(`profile.${artistname}.benefits.fifth`),
    ];
    return benefits.filter((benefit) => benefit !== '');
  };
  const dangerouslySetStrongText = (text: string) => ({ __html: text });
  return (
    <>
      <style>
        {`.u-row {
            display: flex;
            flex-wrap: nowrap;
            margin-left: 0;
            margin-right: 0;
          }
          #u_column_9{
            padding-left: 9rem;
          }
          
          .u-row .u-col {
            position: relative;
            width: 100%;
            padding-right: 0;
            padding-left: 0;
          }
          
          .u-row .u-col.u-col-13p07 {
            flex: 0 0 13.07%;
            max-width: 13.07%;
          }
          
          .u-row .u-col.u-col-13p24 {
            flex: 0 0 13.24%;
            max-width: 13.24%;
          }
          
          .u-row .u-col.u-col-24p31 {
            flex: 0 0 24.31%;
            max-width: 24.31%;
          }
          
          .u-row .u-col.u-col-24p97 {
            flex: 0 0 24.97%;
            max-width: 24.97%;
          }
          
          .u-row .u-col.u-col-37p8 {
            flex: 0 0 37.8%;
            max-width: 37.8%;
          }
          
          .u-row .u-col.u-col-37p89 {
            flex: 0 0 37.89%;
            max-width: 37.89%;
          }
          
          .u-row .u-col.u-col-48p72 {
            flex: 0 0 48.72%;
            max-width: 48.72%;
          }
          
          .u-row .u-col.u-col-50 {
            flex: 0 0 50%;
            max-width: 50%;
          }
          
          .u-row .u-col.u-col-100 {
            flex: 0 0 100%;
            max-width: 100%;
          }
          
          @media (max-width: 767px) {
            .u-row:not(.no-stack) {
              flex-wrap: wrap;
            }
            .u-row:not(.no-stack) .u-col {
              flex: 0 0 100% !important;
              max-width: 100% !important;
            }
            #u_column_8{
              margin-bottom: 1rem;
            }
            #u_column_9 {
              padding-left: 0rem;
            }
          }
          
          body,
          html {
            padding: 0;
            margin: 0
          }
          
          html {
            box-sizing: border-box
          }
          
          *,
          :after,
          :before {
            box-sizing: inherit
          }
          
          html {
            font-size: 14px;
            -ms-overflow-style: scrollbar;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
          }
          
          p {
            margin: 0
          }
          
          form .error-field {
            -webkit-animation-name: shake;
            animation-name: shake;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both
          }
          
          form .error-field input,
          form .error-field textarea {
            border-color: #a94442!important;
            color: #a94442!important
          }
          
          form .field-error {
            padding: 5px 10px;
            font-size: 14px;
            font-weight: 700;
            position: absolute;
            top: -20px;
            right: 10px
          }
          
          form .field-error:after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-top-color: #ebcccc;
            border-width: 5px;
            margin-left: -5px
          }
          
          form .spinner {
            margin: 0 auto;
            width: 70px;
            text-align: center
          }
          
          form .spinner>div {
            width: 12px;
            height: 12px;
            background-color: hsla(0, 0%, 100%, .5);
            margin: 0 2px;
            border-radius: 100%;
            display: inline-block;
            -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both
          }
          
          form .spinner .bounce1 {
            -webkit-animation-delay: -.32s;
            animation-delay: -.32s
          }
          
          form .spinner .bounce2 {
            -webkit-animation-delay: -.16s;
            animation-delay: -.16s
          }
          
          @-webkit-keyframes sk-bouncedelay {
            0%,
            80%,
            to {
              -webkit-transform: scale(0)
            }
            40% {
              -webkit-transform: scale(1)
            }
          }
          
          @keyframes sk-bouncedelay {
            0%,
            80%,
            to {
              -webkit-transform: scale(0);
              transform: scale(0)
            }
            40% {
              -webkit-transform: scale(1);
              transform: scale(1)
            }
          }
          
          @-webkit-keyframes shake {
            0%,
            to {
              -webkit-transform: translateZ(0);
              transform: translateZ(0)
            }
            10%,
            30%,
            50%,
            70%,
            90% {
              -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0)
            }
            20%,
            40%,
            60%,
            80% {
              -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0)
            }
          }
          
          @keyframes shake {
            0%,
            to {
              -webkit-transform: translateZ(0);
              transform: translateZ(0)
            }
            10%,
            30%,
            50%,
            70%,
            90% {
              -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0)
            }
            20%,
            40%,
            60%,
            80% {
              -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0)
            }
          }
          
          @media (max-width:480px) {
            .container {
              max-width: 100%!important
            }
          }
          
          @media (min-width:481px) and (max-width:768px) {
            .hide-tablet {
              display: none!important
            }
          }
          
          .container {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            margin-right: auto;
            margin-left: auto
          }
          
          @media (min-width:576px) {
            .container {
              max-width: 540px
            }
          }
          
          @media (min-width:768px) {
            .container {
              max-width: 720px
            }
          }
          
          @media (min-width:992px) {
            .container {
              max-width: 960px
            }
          }
          
          @media (min-width:1200px) {
            .container {
              max-width: 1140px
            }
          }
          
          a[onclick] {
            cursor: pointer;
          }
          
          body {
            font-family: 'Open Sans', sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            color: #000000;
            background-color: #ffffff;
          }
          
          #u_body a {
            color: #8651ff;
            text-decoration: none;
          }
          
          #u_body a:hover {
            color: #38187f;
            text-decoration: none;
          }
          
          #u_content_button_5 a:hover {
            color: #FFFFFF !important;
            background-color: #38187f !important;
          }
          
          #u_content_button_6 a:hover {
            color: #FFFFFF !important;
            background-color: #38187f !important;
          }
          
          @media (max-width: 480px) {
            #u_content_image_2 .v-src-width {
              width: auto !important;
            }
            #u_content_image_2 .v-src-max-width {
              max-width: 32% !important;
            }
            #u_content_image_3.v-container-padding-padding {
              padding: 10px 10px 20px !important;
            }
            #u_content_image_3 .v-src-width {
              width: auto !important;
            }
            #u_content_image_3 .v-src-max-width {
              max-width: 41% !important;
            }
            #u_content_heading_2.v-container-padding-padding {
              padding: 10px 30px !important;
            }
            #u_content_heading_2 .v-text-align {
              text-align: center !important;
            }
            #u_content_text_3.v-container-padding-padding {
              padding: 10px 30px !important;
            }
            #u_content_text_3 .v-text-align {
              text-align: justify !important;
            }
            #u_content_text_5.v-container-padding-padding {
              padding: 10px 30px !important;
            }
            #u_content_text_5 .v-text-align {
              text-align: left !important;
            }
            #u_content_text_6.v-container-padding-padding {
              padding: 10px 30px !important;
            }
            #u_content_text_6 .v-text-align {
              text-align: justify !important;
            }
            #u_content_button_5 .v-text-align {
              text-align: center !important;
            }
            #u_content_button_6 .v-text-align {
              text-align: center !important;
            }
          }`}
      </style>
      <div
        id="u_body"
        className="u_body"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div id="u_row_3" className="u_row" style={{ padding: '0px' }}>
          <div
            className="container"
            style={{ maxWidth: '100%', margin: '0 auto' }}
          >
            <div className="u-row">
              <div id="u_column_4" className="u-col u-col-37p89 u_column">
                {/* Yellow shape */}
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                >
                  <div
                    id="u_content_image_1"
                    className="u_content_image v-container-padding-padding"
                    style={{ overflowWrap: 'break-word', padding: '0px' }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        lineHeight: '0px',
                        textAlign: 'left',
                      }}
                    >
                      <img
                        alt="yellow_wave"
                        className="v-src-width v-src-max-width"
                        src="https://assets.unlayer.com/projects/73557/1658336736620-yellow_wave.png"
                        style={{ width: '100%', maxWidth: '400px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Yellow shape */}
              {/* Logo Colkie */}
              <div id="u_column_5" className="u-col u-col-24p31 u_column">
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                >
                  <div
                    id="u_content_image_2"
                    className="u_content_image v-container-padding-padding"
                    style={{
                      overflowWrap: 'break-word',
                      padding: '50px 10px 10px',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        lineHeight: '0px',
                        textAlign: 'center',
                      }}
                    >
                      <a href="https://www.colkie.com" target="_blank">
                        <img
                          alt=""
                          className="v-src-width v-src-max-width"
                          src="https://assets.unlayer.com/projects/73557/1658336553261-Logo_Colkie_Purple.png"
                          style={{ width: '40%', maxWidth: '200px' }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Logo Colkie */}
              <div id="u_column_6" className="u-col u-col-37p8 u_column">
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div id="u_row_5" className="u_row" style={{ padding: '0px' }}>
          <div
            className="container"
            style={{ maxWidth: '100%', margin: '0 auto' }}
          >
            <div className="u-row">
              <div id="u_column_11" className="u-col u-col-100 u_column">
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                >
                  <div
                    id="u_content_divider_1"
                    className="u_content_divider v-container-padding-padding"
                    style={{ overflowWrap: 'break-word', padding: '10px 0px' }}
                  >
                    <div style={{ textAlign: 'center', lineHeight: '0' }}>
                      <div
                        style={{
                          borderTopWidth: '0px',
                          borderTopStyle: 'solid',
                          borderTopColor: '#BBBBBB',
                          width: '100%',
                          display: 'inline-block',
                          lineHeight: '0px',
                          height: '0px',
                          verticalAlign: 'middle',
                        }}
                      >
                        {' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="u_row_4" className="u_row" style={{ padding: '0px' }}>
          <div
            className="container"
            style={{ maxWidth: '100%', margin: '0 auto' }}
          >
            <div className="u-row">
              <div id="u_column_7" className="u-col u-col-13p07 u_column">
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                ></div>
              </div>
              <div id="u_column_8" className="u-col u-col-24p97 u_column">
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                >
                  <div
                    id="u_content_image_3"
                    className="u_content_image v-container-padding-padding"
                    style={{ overflowWrap: 'break-word', padding: '0px' }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        lineHeight: '0px',
                        textAlign: 'center',
                      }}
                    >
                      <img
                        alt=""
                        className="v-src-width v-src-max-width"
                        src={t(`profile.${artistname}.mainImage`)}
                        style={{ width: '100%', maxWidth: '280px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="u_column_9" className="u-col u-col-48p72 u_column">
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                >
                  <div
                    id="u_content_heading_2"
                    className="u_content_heading v-container-padding-padding"
                    style={{ overflowWrap: 'break-word', padding: '10px' }}
                  >
                    <h1
                      className="v-text-align"
                      style={{
                        margin: '0px',
                        color: '#8651ff',
                        lineHeight: '140%',
                        textAlign: 'left',
                        wordWrap: 'break-word',
                        fontWeight: 'normal',
                        fontFamily: 'arial,helvetica,sans-serif',
                        fontSize: '22px',
                      }}
                    >
                      <div>
                        <div>
                          <strong>{t(`profile.${artistname}.title`)}</strong>
                        </div>
                      </div>
                    </h1>
                  </div>
                  <div
                    id="u_content_text_3"
                    className="u_content_text v-container-padding-padding"
                    style={{ overflowWrap: 'break-word', padding: '10px' }}
                  >
                    <div
                      className="v-text-align"
                      style={{
                        lineHeight: '140%',
                        textAlign: 'left',
                        wordWrap: 'break-word',
                      }}
                    >
                      <p style={{ fontSize: '14px', lineHeight: '140%' }}>
                        <span
                          style={{ fontSize: '16px', lineHeight: '22.4px' }}
                          dangerouslySetInnerHTML={dangerouslySetStrongText(
                            t(`profile.${artistname}.benefits.title`)
                          )}
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div
                    id="u_content_text_5"
                    className="u_content_text v-container-padding-padding"
                    style={{
                      overflowWrap: 'break-word',
                      padding: '10px 10px 10px 50px',
                    }}
                  >
                    <div
                      className="v-text-align"
                      style={{
                        lineHeight: '140%',
                        textAlign: 'left',
                        wordWrap: 'break-word',
                      }}
                    >
                      {benefitsBuilder().map((benefit, index) => (
                        <p
                          key={index}
                          style={{ fontSize: '14px', lineHeight: '140%' }}
                        >
                          <span
                            style={{ fontSize: '16px', lineHeight: '22.4px' }}
                          >
                            <span
                              style={{
                                color: '#8651ff',
                                fontSize: '16px',
                                lineHeight: '22.4px',
                              }}
                            >
                              ⦿{' '}
                            </span>
                            {benefit}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                  <div
                    id="u_content_text_6"
                    className="u_content_text v-container-padding-padding"
                    style={{ overflowWrap: 'break-word', padding: '10px' }}
                  >
                    <div
                      className="v-text-align"
                      style={{
                        lineHeight: '140%',
                        textAlign: 'left',
                        wordWrap: 'break-word',
                      }}
                    >
                      <p style={{ fontSize: '14px', lineHeight: '140%' }}>
                        <span
                          style={{ fontSize: '16px', lineHeight: '22.4px' }}
                          dangerouslySetInnerHTML={dangerouslySetStrongText(
                            t(`profile.${artistname}.downloadText`)
                          )}
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div
                    id="u_content_button_5"
                    className="u_content_button v-container-padding-padding"
                    style={{
                      overflowWrap: 'break-word',
                      padding: '20px 10px 10px',
                    }}
                  >
                    <div className="v-text-align" style={{ textAlign: 'left' }}>
                      <a
                        href={t('profile.buttons.androidLink')}
                        target="_blank"
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#8651ff',
                          borderRadius: '30px',
                          lineHeight: '120%',
                          display: 'inline-block',
                          textDecoration: 'none',
                          textAlign: 'center',
                          padding: '10px 25px',
                          width: 'auto',
                          maxWidth: '100%',
                          wordWrap: 'break-word',
                        }}
                      >
                        <span
                          style={{ fontSize: '16px', lineHeight: '19.2px' }}
                        >
                          <span
                            style={{ lineHeight: '19.2px', fontSize: '16px' }}
                          >
                            {t('profile.buttons.androidText')}
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div
                    id="u_content_button_6"
                    className="u_content_button v-container-padding-padding"
                    style={{ overflowWrap: 'break-word', padding: '10px' }}
                  >
                    <div className="v-text-align" style={{ textAlign: 'left' }}>
                      <a
                        href={t('profile.buttons.iosLink')}
                        target="_blank"
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#8651ff',
                          borderRadius: '30px',
                          lineHeight: '120%',
                          display: 'inline-block',
                          textDecoration: 'none',
                          textAlign: 'center',
                          padding: '10px 44px',
                          width: 'auto',
                          maxWidth: '100%',
                          wordWrap: 'break-word',
                        }}
                      >
                        <span
                          style={{ fontSize: '16px', lineHeight: '19.2px' }}
                        >
                          <span
                            style={{ lineHeight: '19.2px', fontSize: '16px' }}
                          >
                            {t('profile.buttons.iosText')}
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="u_column_10" className="u-col u-col-13p24 u_column">
                <div
                  style={{
                    padding: '0px',
                    borderTop: '0px solid transparent',
                    borderLeft: '0px solid transparent',
                    borderRight: '0px solid transparent',
                    borderBottom: '0px solid transparent',
                    borderRadius: '0px',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div id="u_row_7" className="u_row" style={{ padding: '0px' }}>
            <div
              className="container"
              style={{ maxWidth: '100%', margin: '0 auto' }}
            >
              <div className="u-row">
                <div id="u_column_13" className="u-col u-col-50 u_column">
                  <div
                    style={{
                      padding: '0px',
                      borderTop: '0px solid transparent',
                      borderLeft: '0px solid transparent',
                      borderRight: '0px solid transparent',
                      borderBottom: '0px solid transparent',
                      borderRadius: '0px',
                    }}
                  ></div>
                </div>
                <div id="u_column_14" className="u-col u-col-50 u_column">
                  <div
                    style={{
                      padding: '0px',
                      borderTop: '0px solid transparent',
                      borderLeft: '0px solid transparent',
                      borderRight: '0px solid transparent',
                      borderBottom: '0px solid transparent',
                      borderRadius: '0px',
                    }}
                  >
                    <div
                      id="u_content_image_4"
                      className="u_content_image v-container-padding-padding"
                      style={{ overflowWrap: 'break-word', padding: '0px' }}
                    >
                      <div
                        style={{
                          position: 'relative',
                          lineHeight: '0px',
                          textAlign: 'right',
                        }}
                      >
                        <img
                          alt="purple wave"
                          className="v-src-width v-src-max-width"
                          src="https://assets.unlayer.com/projects/73557/1658336901996-purple_wave.png"
                          style={{ width: '100%', maxWidth: '400px' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="u_row_8" className="u_row" style={{ padding: '0px' }}>
            <div
              className="container"
              style={{
                maxWidth: '100%',
                margin: '0 auto',
                backgroundColor: '#8651ff',
              }}
            >
              <div className="u-row">
                <div id="u_column_15" className="u-col u-col-100 u_column">
                  <div
                    style={{
                      padding: '0px',
                      borderTop: '0px solid transparent',
                      borderLeft: '0px solid transparent',
                      borderRight: '0px solid transparent',
                      borderBottom: '0px solid transparent',
                      borderRadius: '0px',
                    }}
                  >
                    <div
                      id="u_content_text_4"
                      className="u_content_text v-container-padding-padding"
                      style={{ overflowWrap: 'break-word', padding: '10px' }}
                    >
                      <div
                        className="v-text-align"
                        style={{
                          lineHeight: '140%',
                          textAlign: 'left',
                          wordWrap: 'break-word',
                        }}
                      >
                        <p style={{ fontSize: '14px', lineHeight: '140%' }}>
                          <span
                            style={{
                              color: '#ffffff',
                              fontSize: '14px',
                              lineHeight: '19.6px',
                            }}
                          >
                            <a
                              rel="noopener"
                              href="https://www.colkie.com"
                              target="_blank"
                              style={{ color: '#ffffff' }}
                            >
                              © 2022 Colkie
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default ProfilePage;
