import { ComponentProps, FunctionComponent, ReactElement } from 'react';
import styles from './styles.scss';

export interface IInputProps extends ComponentProps<'input'> {
    icon?: ReactElement<any, any>;
    hasError?: boolean;
}

const Input: FunctionComponent<IInputProps> = (props: IInputProps) => (
        <div className={`${styles.inputContainer}`}>
            <input {...props} className={`${styles.input}  ${props.hasError && styles.error } ${props.icon && styles.withIcon }`} />
            {props.icon && (
                <div className={styles.icon}>
                    {props.icon}
                </div>
            )}
        </div>
    );

export { Input };
