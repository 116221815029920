import { getMe, getProfile } from 'services/domain/user';
import { userActions } from './reducer';


const getUserProfile = (username: string) => ({
    type: userActions.PROFILE,
    payload: getProfile(username).then(data => data).catch(error => Promise.reject(error.response))
});

const getUserMe = () => ({
    type: userActions.GET_ME,
    payload: getMe().then(data => data).catch(error => Promise.reject(error.response))
});
export { getUserProfile, getUserMe };
