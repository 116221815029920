import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createPromise } from 'redux-promise-middleware';
import loadingIndicator from './middleware/loadingMiddleware';
import reducers from './reducers';

const middlewares = [createPromise(), loadingIndicator];

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(...middlewares)));
const persistor = persistStore(store);
export default { store, persistor };