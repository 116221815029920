import { language } from 'config/config';
import { Price } from 'services/domain/models/channel.model';

const zeroDecimal = ['JPY', 'KRW', 'CLP'];


export const getLanguageCode = (lang?: string): string => (lang ? language[lang] ? language[lang] : lang : 'en-US');
export const localizePrice = (price?: Price): number => {
    if (!price?.amount || !price?.currency) {
        return 0;
    }
    return zeroDecimal.includes(price.currency.toLocaleUpperCase()) ? parseInt(price.amount, 10) : parseInt(price?.amount, 10) / 100;
};