import { doSignIn } from 'services/domain/auth/auth';
import { SignInRequest } from 'services/domain/auth/models/signin.model';
import { authActions } from './reducer';

const login = (params: SignInRequest) => ({
        type: authActions.SIGNIN,
        payload: doSignIn(params).then(data => data).catch(errorResponse => {
            const {data: error} = errorResponse.response;
            return Promise.reject({data:{error}});
        })
    });

export { login };
