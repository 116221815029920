import { ComponentProps, FunctionComponent } from 'react';
import styles from './styles.scss';

export interface ILink extends ComponentProps<'a'> {
    label?: string;
    underline?: boolean;
}
const Link: FunctionComponent<ILink> = (props: ILink) => (
        <a {...props} className={`${styles.link} ${props.underline && styles.underline}`}>{props.label}</a>
    );

export default Link;