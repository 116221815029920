import { globalActions } from './actions';

interface GlobalInitialState {
    loading: boolean;
}
interface GlobalAction {
    type: string;
    payload: string;
}
const initialState: GlobalInitialState = {
    loading: false
};

const globalReducer = (state: GlobalInitialState = initialState, action: GlobalAction ) => {
    switch(action.type) {
        case globalActions.START_LOADING:
            return {...state, loading: true};
        case globalActions.STOP_LOADING:
            return {...state, loading: false};
        default: return state;
    }
};

export { globalReducer };
