import { ComponentProps, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';

enum Tier {
  GOLD = 'GOLD',
  BASE = 'BASE',
}

interface IMemberCard extends ComponentProps<'div'> {
  tier: Tier;
  artistImage: string;
  artistUsername: string;
  fanUsername: string;
  firstSubscriptionDate?: string;
  memberId?: string;
  membershipCount?: number;
}

const MemberCard: FunctionComponent<IMemberCard> = (props: IMemberCard) => {
  const { t } = useTranslation();
  const tierClass =
    props.tier === Tier.GOLD ? styles.tierGold : styles.tierBase;

  const memberSince =
    !!props.firstSubscriptionDate && new Date(props.firstSubscriptionDate);

  const pluralizeWord = (
    singularWord: string,
    pluralWord: string,
    count: number
  ) => (count > 1 ? pluralWord : singularWord);

  const formatMembershipCount = (count: number) => {
    if (count >= 1 && count < 12) {
      return `${count} ${pluralizeWord('month', 'months', count)}`;
    }

    const years = Math.floor(count / 12);
    const months = count - years * 12;
    const monthYearString = months
      ? `and ${months} ${pluralizeWord('month', 'months', months)}`
      : '';

    return `${years} ${pluralizeWord(
      'year',
      'years',
      years
    )} ${monthYearString}`;
  };
  return (
    <div className={`${styles.memberCard} ${tierClass}`}>
      <div className={styles.memberCardHeader}>
        <figure className={styles.artistImage}>
          <img src={props.artistImage} alt={props.artistUsername} />
        </figure>
        <div className={styles.communityDescription}>
          <p className={styles.artistName}>{props.artistUsername}</p>
          <p className={styles.officialMember}>
            {t('membercard.officialMember')}
          </p>
        </div>
      </div>
      <div className={styles.memberCardBody}>
        <p className={styles.fanUsername}>{props.fanUsername}</p>
        <div className={styles.membershipDetails}>
          {props.membershipCount && (
            <p>{`${t('membercard.memberFor')} ${formatMembershipCount(
              props.membershipCount
            )}`}</p>
          )}
          {props.memberId && <p>{`ID: ${props.memberId}`}</p>}
        </div>
      </div>
      <div className={styles.memberCardFooter}>
        {memberSince && (
          <p>
            {t('membercard.memberSince')}
            {` ${memberSince.getUTCDate()} ${memberSince.toLocaleString(
              'default',
              {
                month: 'long',
              }
            )} ${memberSince.getUTCFullYear()}`}
          </p>
        )}
      </div>
    </div>
  );
};

export { MemberCard, Tier };
