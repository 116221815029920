import { startLoading, stopLoading } from 'redux/modules/global/actions';

const loadingIndicator = ({ dispatch }: any) => (next: any) => (
    action: any
) => {
    const exceptions: any = [];
    if (action && action.type) {
        if (
            exceptions.filter((type: string) => type === action.type).length === 0
        ) {
            if (action.type.substring(action.type.length - 7) === 'PENDING') {
                dispatch(startLoading());
            } else if (
                action.type.substring(action.type.length - 9) === 'FULFILLED'
            ) {
                dispatch(stopLoading());
            } else if (
                action.type.substring(action.type.length - 8) === 'REJECTED'
            ) {
                dispatch(stopLoading());
            }
        }
    }

    return next(action);
};

export default loadingIndicator;
