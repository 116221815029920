import Error404 from 'pages/errors/404/404';
import Login from 'pages/login';
import ProfilePage from 'pages/profile';
import SignUpPage from 'pages/signup/signup';
import SubscriptionCancelPage from 'pages/subscription/cancel/subscription.cancel';
import SubscriptionPage from 'pages/subscription/subscription';
import SubscriptionSuccessPage from 'pages/subscription/success/subscription.success';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { RootReducer } from 'redux/reducers';
import ROUTES from 'routes';
import MainLayout from './mainLayout/MainLayout';

const AuthRoute = () => {
    const auth = useSelector(({auth: {token}}: RootReducer) => token);
    if(!auth){
        window.localStorage.setItem('redirect', window.location.pathname);
        return <Navigate to={ROUTES.LOGIN} replace/>;
    }
    return <MainLayout />;
};
const RoutingContainer = () => (
    <Router>
        <Routes>
            <Route path={ROUTES.AUTH} element={<MainLayout />} >
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.SIGNUP} element={<SignUpPage />} />
            </Route>
            <Route path={ROUTES.HOME} element={<AuthRoute />} >
                <Route path={ROUTES.SUBSCRIPTION} element={<SubscriptionPage />} />
            </Route>
            <Route path={ROUTES.SUBSCRIPTION_CANCEL} element={<SubscriptionCancelPage />} />
            <Route path={ROUTES.SUBSCRIPTION_SUCCESS} element={<SubscriptionSuccessPage />} />
            <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
            <Route path={ROUTES.ERROR._404} element={<Error404 />} />
            <Route path="*" element={<Error404 />} />
        </Routes>
    </Router>
);

export default RoutingContainer;