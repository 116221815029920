import { AxiosResponse } from 'axios';

const Actions = {
    SIGNIN: 'SIGNIN',
    SIGNIN_PENDING: 'SIGNIN_PENDING',
    SIGNIN_FULFILLED: 'SIGNIN_FULFILLED',
    SIGNIN_REJECTED: 'SIGNIN_REJECTED',
};

enum ApiError {
    UNVERIFIED_EMAIL = '0001',
}
interface LoginError {
    errorCode: ApiError;
    message: string;
}
interface UserInitialState {
    token: string | null;
    error: LoginError | null;
}

interface UserAction {
    type: string;
    payload: AxiosResponse<UserInitialState>;
}


const initialState: UserInitialState = {
    token: null,
    error: null
};

const authReducer = (state: UserInitialState = initialState, action: UserAction) => {
    switch(action.type) {
        case Actions.SIGNIN_PENDING:
            return {...state, error: null};
        case Actions.SIGNIN_FULFILLED:
            return {...state, token: action.payload.data.token, error: null};
        case Actions.SIGNIN_REJECTED:
            switch(action.payload.data.error?.errorCode) {
                case ApiError.UNVERIFIED_EMAIL:
                    return {...state, error: {errorCode: ApiError.UNVERIFIED_EMAIL, message: 'Email is not verified'}};
                default: return {...state, error: {errorCode: ApiError.UNVERIFIED_EMAIL, message: 'Email is not verified'}};
            }
        default: return state;
    }
};

export { authReducer, Actions as authActions };
