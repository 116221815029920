import Robot from 'assets/errors/illustration/robot.png';
import ErrorLayout, { ErrorProps } from 'pages/errors/error';
import { ComponentProps, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ROUTES from 'routes';


const SubscriptionCancelPage: FunctionComponent<ComponentProps<'div'>> = () => {
    const navigate = useNavigate();
    const { localStorage } = window;
    const channelId = localStorage.getItem('productId');
    const { t } = useTranslation();

    useEffect(() => {
        if(!channelId) {
            navigate(ROUTES.ERROR._404);
        }
    }, []);


    const handler = () => {
        if (channelId) {
            localStorage.removeItem('productId');
            navigate(ROUTES.SUBSCRIPTION.replace(':channelId', channelId) );
        }
    };
    const error: ErrorProps = {
        image: Robot,
        title: t('subscriptionCancel.title'),
        subtitle: t('subscriptionCancel.subtitle'),
        buttonLabel: t('subscriptionCancel.buttons.tryAgain'),
        handler
    };

    return (<ErrorLayout {...error}/>);
};

export default SubscriptionCancelPage;