import { AxiosResponse } from 'axios';
import { AccessLevel, Channel, Price } from 'services/domain/models/channel.model';
import { ActiveSubscription, ActiveSubscriptionsResponse } from 'services/domain/models/subscription.model';

const Actions = {
    SUBSCRIBE: 'SUBSCRIBE',
    SUBSCRIBE_PENDING: 'SUBSCRIBE_PENDING',
    SUBSCRIBE_FULFILLED: 'SUBSCRIBE_FULFILLED',
    SUBSCRIBE_REJECTED: 'SUBSCRIBE_REJECTED',
    CHANNEL_TO_SUBSCRIBE: 'CHANNEL_TO_SUBSCRIBE',
    CHANNEL_TO_SUBSCRIBE_PENDING: 'CHANNEL_TO_SUBSCRIBE_PENDING',
    CHANNEL_TO_SUBSCRIBE_FULFILLED: 'CHANNEL_TO_SUBSCRIBE_FULFILLED',
    CHANNEL_TO_SUBSCRIBE_REJECTED: 'CHANNEL_TO_SUBSCRIBE_REJECTED',
    SUBSCRIPTION: 'SUBSCRIPTION',
    SUBSCRIPTION_PENDING: 'SUBSCRIPTION_PENDING',
    SUBSCRIPTION_FULFILLED: 'SUBSCRIPTION_FULFILLED',
    SUBSCRIPTION_REJECTED: 'SUBSCRIPTION_REJECTED',
    GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
    GET_SUBSCRIPTIONS_PENDING: 'GET_SUBSCRIPTIONS_PENDING',
    GET_SUBSCRIPTIONS_FULFILLED: 'GET_SUBSCRIPTIONS_FULFILLED',
    GET_SUBSCRIPTIONS_REJECTED: 'GET_SUBSCRIPTIONS_REJECTED',
    GET_LEVEL_PRICE: 'GET_LEVEL_PRICE',
    GET_LEVEL_PRICE_PENDING: 'GET_LEVEL_PRICE_PENDING',
    GET_LEVEL_PRICE_FULFILLED: 'GET_LEVEL_PRICE_FULFILLED',
    GET_LEVEL_PRICE_REJECTED: 'GET_LEVEL_PRICE_REJECTED'
};

interface SubscriptionInitialState {
    channel: Channel | null;
    sessionUrl: string | null;
    highestLevel: AccessLevel | null;
    notFound: boolean;
    subscriptionInfo: ActiveSubscription | null;
    allSubscriptions: ActiveSubscriptionsResponse | null;
    price: Price | null;
}
interface UserAction {
    type: string;
    payload: AxiosResponse;
}


const initialState: SubscriptionInitialState = {
    channel: null,
    sessionUrl: null,
    highestLevel: null,
    notFound: false,
    subscriptionInfo: null,
    allSubscriptions: null,
    price: null
};

const getHighestLevel = (previousLevel: AccessLevel, iter: AccessLevel): AccessLevel => (previousLevel.level > iter.level ? previousLevel : iter);

const subscriptionReducer = (state: SubscriptionInitialState = initialState, action: UserAction) => {
    switch(action.type) {
        case Actions.CHANNEL_TO_SUBSCRIBE_FULFILLED:
            const channel = action.payload.data as Channel;
            const defaultAccess = {level: 0, externalProductId: null, capabilities: []};
            const highestLevel = channel.accessLevels.reduce(getHighestLevel, defaultAccess);
            return {...state, channel, highestLevel, notFound: highestLevel.level === defaultAccess.level};

        case Actions.CHANNEL_TO_SUBSCRIBE_REJECTED:
            if (action.payload.status === 404) {
                return {...state, notFound: true};
            }
            return {...state, channel: null, notFound: true};
        case Actions.GET_SUBSCRIPTIONS_FULFILLED:
            return {...state, allSubscriptions: action.payload.data};
        case Actions.GET_SUBSCRIPTIONS_REJECTED:
            return {...state, allSubscriptions: null};
        case Actions.SUBSCRIPTION_FULFILLED:
            return {...state, subscriptionInfo: action.payload.data};
        case Actions.SUBSCRIPTION_REJECTED:
            return {...state, subscriptionInfo: null};
        case Actions.SUBSCRIBE_FULFILLED:
            return {...state, sessionUrl: action.payload.data};
        case Actions.GET_LEVEL_PRICE_FULFILLED:
            return {...state, price: action.payload.data};
        case Actions.GET_LEVEL_PRICE_REJECTED:
            return {...state, notFound: true};
        default: return state;
    }
};

export { subscriptionReducer, Actions as subscriptionActions };
